import { CircularProgress, MenuItem, Select, styled } from '@material-ui/core';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { collection, query, orderBy, onSnapshot, doc, deleteDoc, getDocs, where, addDoc, serverTimestamp, and } from "firebase/firestore";
import { firestore } from './firebase';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Bar, Legend, Brush } from 'recharts';
import { convertDateToYYYYMMDD, convertOptionsSymbol, mergeNetPremiumAndStockPrices, parseOptionType, parseTickerFromOptionsSymbol } from './Utils';
import { FormControl } from '@mui/material';
import { Info } from 'lucide-react';
import { Tooltip as NextUITooltip } from "@nextui-org/react";
import { set } from 'react-ga';


const getStockPriceData = async (stockTicker, from, to, timespan = 'minute', multiplier = 5) => {
    // Calculate the difference in days between 'from' and 'to' dates
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const diffTime = Math.abs(toDate - fromDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // If the difference is greater than 120 days, adjust timespan and multiplier
    if (diffDays > 90) {
        console.log('Adjusting timespan and multiplier');
        timespan = 'minute';
        multiplier = 30;
    } else if (diffDays < 8) {
        timespan = 'minute';
        multiplier = 1;
    }

    const url = new URL(`https://api.bullflow.io/v1/historicalTickerData`);

    url.searchParams.append('stockTicker', stockTicker);
    url.searchParams.append('from', from);
    url.searchParams.append('to', to);
    url.searchParams.append('timespan', timespan);
    url.searchParams.append('multiplier', multiplier);

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    let data = await response.json();
    return data;
};

const NetPremiumChart = ({ optionsTicker_ }) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const initFromDate = currentDate.toISOString().split('T')[0];
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [firestoreData, setFirestoreData] = useState([]); // New state for Firestore data
    const [activePeriod, setActivePeriod] = useState('1M');
    const [activeTimespan, setActiveTimespan] = useState('hour');
    const [timespanValue, setTimespanValue] = useState('8 Hour');
    const [multiplier, setMultiplier] = useState(1);
    const [from, setFrom] = useState(initFromDate);
    const [period, setPeriod] = useState('1M');
    const to = new Date().toISOString().split('T')[0];
    const [lowestStockPrice, setLowestStockPrice] = useState(0.0);
    const [highestStockPrice, setHighestStockPrice] = useState(0.0);
    const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
    let optionsTicker = optionsTicker_;
    const selectRef = useRef(null);
    // Firestore useEffect
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const tickersRef = collection(firestore, '_tickers', parseTickerFromOptionsSymbol(optionsTicker), 'trades');
                const fromDate = new Date(from);
                const fromTimestamp = Math.floor(fromDate.getTime() / 1000);

                const q = query(tickersRef, orderBy('timestamp', 'desc'), where('timestamp', '>', fromTimestamp));
                const querySnapshot = await getDocs(q);

                // Initialize callsNetPremium and putsNetPremium to avoid potential issues
                let callsNetPremium = 0;
                let putsNetPremium = 0;

                // Map and customize the data
                const firestoreData = querySnapshot.docs.reverse().map(doc => {
                    const data = doc.data();
                    // console.log(`Firestore data timestamp: ${data.timestamp}`);
                    if (data.optionType === 'C') { // Use strict equality check
                        if (data.spread_execution === 'Ask' || data.spread_execution === 'Above Ask') {
                            callsNetPremium += data.premium;
                        } else if (data.spread_execution === 'Bid' || data.spread_execution === 'Below Bid') {
                            callsNetPremium -= data.premium;
                        } else {
                            return {
                                id: doc.id,
                                timestamp: data.timestamp,
                                callsNetPremium: callsNetPremium,
                                putsNetPremium: putsNetPremium,
                            };
                        }

                    } else if (data.optionType === 'P') { // Use strict equality check
                        if (data.spread_execution === 'Ask') {
                            putsNetPremium += data.premium;
                        } else if (data.spread_execution === 'Bid') {
                            putsNetPremium -= data.premium;
                        } else {
                            return {
                                id: doc.id,
                                timestamp: data.timestamp,
                                callsNetPremium: callsNetPremium,
                                putsNetPremium: putsNetPremium,
                            };
                        }
                    }

                    return {
                        id: doc.id,
                        timestamp: data.timestamp, // Example: 1716474475.212
                        callsNetPremium: callsNetPremium,
                        putsNetPremium: putsNetPremium,
                    };
                });
                const firstTimestamp = firestoreData.length > 0 ? firestoreData[0].timestamp : fromTimestamp;
                const firstDate = new Date(firstTimestamp * 1000);
                const fromString = firstDate.toISOString().split('T')[0]; // Convert to yyyy-mm-dd format

                const stockPriceData = await getStockPriceData(parseTickerFromOptionsSymbol(optionsTicker), fromString, to);
                // const mergedData = mergeNetPremiumAndStockPrices(firestoreData, stockPriceData);
                const mergedData = firestoreData.map(item => {

                    // Find the closest stock data using the reduce method
                    const closestStockData = stockPriceData.reduce((prev, curr) => {
                        const currTimestampInSeconds = curr.time;
                        const prevTimestampInSeconds = prev.time;
                        // console.log(`Current timestamp: ${currTimestampInSeconds}, Previous timestamp: ${prevTimestampInSeconds}. Item timestamp: ${item.timestamp}`);
                        return Math.abs(currTimestampInSeconds - item.timestamp) < Math.abs(prevTimestampInSeconds - item.timestamp) ? curr : prev;
                    });

                    // Merge the Firestore data item with the closest stock price
                    const price = closestStockData ? closestStockData.close : null;
                    // console.log(`Closest stock price: ${price}`);
                    // console.log(`Net Premium timestamp: ${item.timestamp}`);
                    // console.log(`Closest stock timestamp: ${closestStockData.t}`);
                    return {
                        ...item,
                        stockPrice: price
                    };
                });
                // find the lowest and highest stock price
                const stockPrices = mergedData.map(item => item.stockPrice);
                const lowestPrice = Math.min(...stockPrices);
                const highestPrice = Math.max(...stockPrices);
                const yAxisDomain = [lowestPrice - (lowestPrice * 0.015), highestPrice + (highestPrice * 0.015)];
                // console.log(`Lowest price: ${lowestPrice}, Highest price: ${highestPrice}`);
                setYAxisDomain(yAxisDomain);
                setFirestoreData(mergedData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [optionsTicker, from, to]);
    const numberFormatter2 = (number) => {
        return number.toFixed(2);
    };
    const CustomTooltip2 = ({ active, payload, label }) => {
        // Memoize the formatted date to prevent re-computation on every render
        const formattedTime = useMemo(() => {
            if (label) {
                const dateObject = new Date(label * 1000);
                const options = {
                    timeZone: 'America/New_York',
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                return dateObject.toLocaleTimeString('en-US', options);
            }
            return '';
        }, [label]);

        if (active && payload && payload.length) {
            const callsNetPremium = payload.find(p => p.dataKey === 'callsNetPremium');
            const putsNetPremium = payload.find(p => p.dataKey === 'putsNetPremium');
            const stockPrice = payload.find(p => p.dataKey === 'stockPrice');

            return (
                <div style={{
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(106, 108, 138, 0.8)',
                    padding: '8px',
                }}>
                    <p className="label" style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`${formattedTime} EST`}</p>
                    {callsNetPremium && <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Net Call Premium: ${numberFormatter(callsNetPremium.value)}`}</p>}
                    {putsNetPremium && <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Net Put Premium: ${numberFormatter(putsNetPremium.value)}`}</p>}
                    {stockPrice && <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Price: ${stockPrice.value.toFixed(2)}`}</p>}
                </div>
            );
        }

        return null;
    };
    const handlePeriodClick = (value) => {
        setActivePeriod(value);
        const currentDate = new Date();
        setPeriod(value);

        switch (value) {
            case '1D':
                // Do not modify currentDate; newFromDate will be today
                break;
            case '7D':
                currentDate.setDate(currentDate.getDate() - 7);
                break;
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1);
                break;
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                break;
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1);
                break;
            case 'MAX':
                currentDate.setFullYear(currentDate.getFullYear() - 5);
                break;
            default:
                break;
        }

        const newFromDate = currentDate.toISOString().split('T')[0];
        setFrom(newFromDate);
    };

    const formatXAxis2 = (tickItem) => {
        const milliseconds = Math.floor(tickItem * 1000);
        const date = new Date(milliseconds);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yy = String(date.getFullYear()).slice(-2);
        return `${mm}/${dd}`;
    };
    const numberFormatter = (number) => {
        const absNumber = Math.abs(number);

        let formattedNumber;
        if (absNumber >= 1000 && absNumber < 1000000) {
            formattedNumber = `${(absNumber / 1000).toFixed(1).replace(/\.0$/, '')}k`;
        } else if (absNumber >= 1000000) {
            formattedNumber = `${(absNumber / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
        } else {
            formattedNumber = absNumber.toString();
        }

        // Reapply the sign to the formatted number
        return number < 0 ? `-${formattedNumber}` : formattedNumber;
    };

    return (
        <>
            {loading ? (
                <div>
                    <CircularProgress style={{ padding: '0px', margin: '0px' }} />
                </div>
            ) : (
                <div style={{ textAlign: 'center', height: 'auto', width: '100%' }}>
                    <div style={{ display: 'flex', height: 'auto', justifyContent: 'space-between', alignItems: 'center', color: 'white', marginBottom: '8px' }}>
                        <p style={{ margin: 0, textAlign: 'right', color: 'white', fontWeight: 500 }}>
                            {/* <span style={{ color: parseOptionType(optionsTicker).includes('P') ? '#fa0043' : '#02d42c' }}>
                                {convertOptionsSymbol(optionsTicker)}
                            </span> */}
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl variant="outlined" size="small" style={{ color: 'white', borderColor: 'white' }}>
                                <Select
                                    labelId="time-period-select-label"
                                    id="time-period-select-2"
                                    value={activePeriod}
                                    onChange={(event) => handlePeriodClick(event.target.value)}
                                    MenuProps={{ style: { color: 'white' } }}
                                    disableUnderline
                                    inputProps={{
                                        style: { color: 'blue' }
                                    }}
                                    style={{ color: 'white', borderColor: 'white' }}
                                >
                                    <MenuItem value="1D">1D</MenuItem>
                                    <MenuItem value="7D">7D</MenuItem>
                                    <MenuItem value="1M">1M</MenuItem>
                                    <MenuItem value="3M">3M</MenuItem>
                                    <MenuItem value="1Y">1Y</MenuItem>
                                    <MenuItem value="MAX">MAX</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                <h2 style={{ textAlign: 'center', color: 'white', marginRight: '10px' }}>{parseTickerFromOptionsSymbol(optionsTicker)} Unusual Net Flow</h2>
                                <NextUITooltip closeDelay={0.5} delay={350} content=
                                    "Net Put/Call Premium is calculated by subtracting the total value of unusual options at the bid from unusual options at the ask. Rising NCP (Net Call Premium) indicates bullish sentiment, rising NPP indicates bearish sentiment.">
                                    <Info size={12.5} style={{ color: '#4a4a4a', cursor: 'pointer' }} />
                                </NextUITooltip>
                            </div>

                            <ComposedChart
                                width={600}
                                height={400}
                                data={firestoreData}
                                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                            >
                                <XAxis dataKey="timestamp" tickFormatter={formatXAxis2} />
                                <YAxis yAxisId="left" orientation="left" tickFormatter={numberFormatter} />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    stroke="#FFD700"
                                    tickFormatter={numberFormatter2}
                                    domain={yAxisDomain}
                                />
                                {/* <CartesianGrid stroke="#4a4a4a" vertical={false} /> */}
                                <Line
                                    yAxisId="left"
                                    type="monotone"
                                    dataKey="callsNetPremium"
                                    stroke="#20d46b"
                                    strokeWidth={2.25}
                                    connectNulls={true}
                                    dot={false}
                                    isAnimationActive={false}
                                />
                                <Line
                                    yAxisId="left"
                                    type="monotone"
                                    dataKey="putsNetPremium"
                                    stroke="#cc3753"
                                    strokeWidth={2.25}
                                    connectNulls={true}
                                    dot={false}
                                    isAnimationActive={false}
                                />
                                <Line
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="stockPrice"
                                    stroke="#FFD700"
                                    strokeWidth={1.75}
                                    connectNulls={true}
                                    dot={false}
                                    isAnimationActive={false}
                                />
                                <Tooltip content={<CustomTooltip2 />} />
                                <Legend
                                    wrapperStyle={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        marginTop: '10px',
                                    }}
                                    formatter={(value) => {
                                        switch (value) {
                                            case 'callsNetPremium':
                                                return 'Net Call Premium';
                                            case 'putsNetPremium':
                                                return 'Net Put Premium';
                                            case 'stockPrice':
                                                return `${parseTickerFromOptionsSymbol(optionsTicker)} Price`;
                                            default:
                                                return value;
                                        }
                                    }}
                                />
                            </ComposedChart>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default NetPremiumChart;
