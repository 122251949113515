import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SignUpScreen from './SignUpScreen';
import SignInScreen from './SignInScreen';
import RedeemACodeScreen from './RedeemACodeScreen';
import SignInWithPromoScreen from './SignInWithPromoScreen';
import './GoldButton.css';
import NoSubscription from './NoSubscription';
import Footer from './footer';
import './footer.css';
import TermsOfService from './TOS';
import ReactGA from 'react-ga';
import SubVerification from './SubVerification';
import BlogListings from './BlogListings';
import { Helmet } from 'react-helmet';
import RealTimeOptionsBasics from './RealTimeOptionsBasics';
import GreeksBlogPost from './GreeksBlogPost';
import WheelStrategyBlogPost from './WheelStratBlogPost';
import TimeDecayOptions from './ThetaDecayBlogPost';
import addEventToFirestore from './EventHelper';
import FAQComponent from './FAQ';
import ReadingUnusualOptionsFlow from './ReadingUnusualOptionsFlowBlog';
import BlocksVsSweepsBlog from './BlocksVsSweepsBlog';
import CheapestOptionFlowBlog from './blogs/CheapestOptionFlowBlog';
import EmailVerification from './pages/EmailVerificationScreen';
import PasswordReset from './pages/PasswordResetScreen';
import RenewSubscriptionScreen from './pages/RenewSubscription';
import MainDashboard from './NewDashboardComponents/MainDashboard';
import NewNavigationBar from './LandingPageComponents/NewNavigationBar';
import { ShootingStarButton } from './LandingPageComponents/ShootingStarButton';
import TitleAndPromoImage from './LandingPageComponents/TitleAndPromoImage';
import { LampDemo } from './components/ui/lamp';
import InfoCardRow from './LandingPageComponents/InfoCardRow';
import FullWidthVideo from './DashboardComponents/FullWidthVideo';
import TradeTypeCardRow from './LandingPageComponents/3DTradeTypeCardRow';
import ScrollingImages from './LandingPageComponents/ScrollingImages';
import NewChartsPage from './NewPagesComponents/NewChartsPage';
import NewsPage from './NewPagesComponents/NewsPage';
import HomePage from './NewPagesComponents/HomePage';
import TickerAnalysisPage from './NewPagesComponents/TickerAnalysis';
import UpdatesPage from './pages/UpdatesPage';
import CallToAction from './LandingPageComponents/CallToAction';
import NewPricingScreen from './NewPricingScreen';
import ContactPage from './NewPagesComponents/ContactPage';
import VladAccessPage from './NewPagesComponents/VladAccessPage';
import SweepsBlog from './SweepsBlog';
import BlocksBlog from './BlocksBlog';
import { InfiniteMovingCards } from './components/ui/infinite-moving-cards';
import SplitsBlog from './SplitsBlog';
import BullflowTwoPointOh from './Bullflowtwopointoh';
import UpdatedHistoricalFlowDashboard from './NewDashboardComponents/UpdatedHistoricalFlow';
import APIEmailCollectionComponent from './pages/ApiEmailCollectionPage';

ReactGA.initialize('G-YKZZT8E9J6');
ReactGA.pageview(window.location.pathname + window.location.search);

const cardItems = [
  {
    quote: '"Some big gains this AM thanks to $hood. Shoutout to @BullflowIO for the great flow alerts!"\n\n',
    name: "@mog_trade_",
    title: "Day Trader on X"
  },
  {
    quote: '"Also shoutout to @BullflowIO as well, for flagging all the $ARM flow and having an awesome yet surprisingly cheap options flow platform."',
    name: "@wrongtrade",
    title: "Investor on X"
  }, {
    quote: '"S/O to @BullflowIO for being the most affordable live options flow data platform on the market!" \n\n',
    name: "@josh_puharic",
    title: "Owner of Tidal Trades"
  }, {
    quote: '"whether we finish with a green candle today or not, liquidity seems to be flipping positive (green) on the daily timeframe! And all that spotted with @BullflowIO🙏"',
    name: "@i_kiok",
    title: "Trader on X"
  },


];
const App = () => {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [isDesktopOrLaptop, setIsDesktopOrLaptop] = useState(false);
  const [variant, setVariant] = useState('');

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    setIsDesktopOrLaptop(mediaQuery.matches);

    const handleResize = () => setIsDesktopOrLaptop(mediaQuery.matches);
    mediaQuery.addListener(handleResize);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);

  useEffect(() => {
    const handleError = (message, source, lineno, colno, error) => {
      console.error('An unhandled error occurred:', message, error);
      setHasError(true);
    };

    const handleRejection = (event) => {
      console.error('Unhandled promise rejection:', event.reason);
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, []);

  useEffect(() => {
    addEventToFirestore('Main page loaded');
  }, []);

  // A/B/C Testing Logic
  useEffect(() => {
    // Check if variant is already stored in localStorage
    const storedVariant = localStorage.getItem('variant');
    if (storedVariant) {
      setVariant(storedVariant);
    } else {
      // Randomly assign variant A, B, or C
      const variants = ['A', 'B', 'C'];
      const randomVariant = variants[Math.floor(Math.random() * variants.length)];
      setVariant(randomVariant);
      localStorage.setItem('variant', randomVariant);
    }
  }, []);

  // Define title and subtitle for each variant
  const variantContent = {
    A: {
      titleText: 'Follow the Smart Money',
      subtitleText: 'Uncover the options trades of hedge funds and institutional investors in real-time',
    },
    B: {
      titleText: 'The #1 Flow Trading Platform & Community',
      subtitleText: 'Learn to follow the options trades of hedge funds and institutional quants in real-time',
    },
    C: {
      titleText: 'Learn to Flow Trade Like a Pro',
      subtitleText: 'Powerful options scanner, engaging community, and high quality educational videos.',
    },
  };

  if (hasError) {
    return (
      <div style={{ color: 'red' }}>
        Something went wrong. Please contact: support@bullflow.io or DM @bullflowio on X/Twitter
      </div>
    );
  }
  return (
    <div className="app flex flex-col justify-center items-center text-white relative min-h-screen min-w-full bg-black">      <Routes>
      <Route path="/" element={
        <>
          <Helmet>
            <title>BullFlow - Real-Time Unusual Options Flow Platform</title>
            <meta name="description" content="BullFlow.io is a real-time unusual options flow tracker that provides advanced tools to view and filter options trades as they happen in the market." />
            <meta name="keywords" content="BullFlow, unusual options flow, real-time options tracker, options trading, market analysis, trading tools, historical options flow" />
            <meta property="og:title" content="BullFlow - Real-Time Unusual Options Flow Platform" />
            <meta property="og:description" content="BullFlow.io is a real-time unusual options flow tracker that provides advanced tools to view and filter options trades as they happen in the market." />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <NewNavigationBar />
          {/* <ShootingStarButton
                text="Try it out"
                onClick={() => {
                  window.gtag('event', 'Start Trial Main Button', {
                    event_category: 'Button',
                    event_label: 'Start Trial Main Button',
                    value: 1,
                    variant: variant, // Include the variant in the event parameters
                  });
                  navigate('/flow');
                }}
              /> */}
                      {variant && (
                <TitleAndPromoImage
                  titleText={variantContent[variant].titleText}
                  subtitleText={variantContent[variant].subtitleText}
                />
              )}

          <LampDemo />
          <InfoCardRow />
          <FullWidthVideo />
          <TradeTypeCardRow />
          <ScrollingImages />
          {isDesktopOrLaptop && (
            <InfiniteMovingCards
              items={cardItems}
              direction="left"
              pauseOnHover={false}
              speed="normal"
              className="custom-class-name"
            />
          )}
          <CallToAction />
          <Footer />
        </>
      } />
      <Route path="/login" element={
        <>
          <Helmet>
            <title>BullFlow.io Sign In</title>
            <meta name="description" content="" />
            <meta property="og:title" content="BullFlow.io - Sign In" />
            <meta property="og:description" content="Sign in to BullFlow.io" />
            <meta property="og:url" content={window.location.href} />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <SignInScreen />
          <Footer />
        </>
      } />
      <Route path="/updates" element={
        <>
          <NewNavigationBar />
          <UpdatesPage />
        </>
      } />
      <Route path="/TermsOfService" element={

        <>
          <NewNavigationBar />
          <TermsOfService />
        </>
      } />
      <Route path="/SignUp" element={
        <>
          <SignUpScreen />
          <Footer />
        </>
      } />
      {/* <Route path="contact-support" element={
          <>
            <StickyTopBar />
            <ContactForm />
            <Footer />
          </>
        }
        /> */}
      <Route path="/PromoAuthentication" element={

        <>
          <NewNavigationBar />
          <SignInWithPromoScreen />
          <Footer />
        </>
      } />
      <Route path="/reset-password" element={

        <>
          <NewNavigationBar />
          <PasswordReset />
          <Footer />
        </>
      } />
      <Route path="/email-verification" element={

        <>
          <EmailVerification />
        </>
      } />
      <Route path="/RedeemACode" element={
        <>
          <NewNavigationBar />
          <RedeemACodeScreen />
          <Footer />
        </>
      } />
      <Route path="/api" element={
        <>
          <NewNavigationBar />
          <APIEmailCollectionComponent />
          <Footer />
        </>
      } />
      <Route path="/app" element={<MainDashboard />} />
      <Route path="/HistoricalFlow" element={<UpdatedHistoricalFlowDashboard />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/flow" element={<MainDashboard />} />
      <Route path="/charts" element={<NewChartsPage />} />
      <Route path="/news" element={
        <>
          <NewsPage />
        </>
      } />
      <Route path="/tester-login" element={<VladAccessPage />} />
      <Route path="/vlad" element={<VladAccessPage />} />
      <Route path="/historical-flow" element={<UpdatedHistoricalFlowDashboard />} />
      <Route path="/bullflow2" element={<BullflowTwoPointOh />} />
      <Route path="/pricing" element={
        <>
          <NewNavigationBar />
          <NewPricingScreen />
          <Footer />
        </>
      } />
      <Route path="/NoSubscription" element={

        <>
          <NewNavigationBar />
          <NoSubscription />
          <Footer />
        </>
      } />
      <Route path="/contact" element={
        <>
          <NewNavigationBar />
          <ContactPage />
          <Footer />
        </>
      } />
      <Route path="/renew" element={

        <>
          <RenewSubscriptionScreen />
        </>
      } />
      <Route path="/verification" element={

        <>
          <NewNavigationBar />
          <SubVerification />
        </>
      } />
      <Route path="/blog" element={
        <>
          <NewNavigationBar />
          <BlogListings />
          <Footer />
        </>
      } />
      <Route path="/faq" element={
        <>
          <NewNavigationBar />
          <FAQComponent />
          <Footer />
        </>
      } />
      <Route path="/blog/real-time-options-basics" element={
        <>
          <NewNavigationBar />
          <RealTimeOptionsBasics />
          <Footer />
        </>
      } />
      <Route path="/blog/decoding-the-greek-delta-gamma-theta-and-vega-in-options" element={
        <>
          <NewNavigationBar />
          <GreeksBlogPost />
          <Footer />
        </>
      } />
      <Route path="/blog/mastering-the-wheel-srategy-in-options-trading" element={
        <>
          <NewNavigationBar />
          <WheelStrategyBlogPost />
          <Footer />
        </>
      } />
      <Route path="/blog/time-decay-in-options-in-depth-look-at-theta-decay" element={
        <>
          <NewNavigationBar />
          <TimeDecayOptions />
          <Footer />
        </>
      } />
      <Route path="/blog/reading-unusual-options-flow" element={
        <>
          <NewNavigationBar />
          <ReadingUnusualOptionsFlow />
          <Footer />
        </>
      } />
      <Route path="/blog/understanding-options-sweep-trades" element={
        <>
          <NewNavigationBar />
          <SweepsBlog />
          <Footer />
        </>
      } />
      <Route path="/blog/understanding-options-split-trades" element={
        <>
          <NewNavigationBar />
          <SplitsBlog />
          <Footer />
        </>
      } />
      <Route path="/blog/understanding-options-block-trades" element={
        <>
          <NewNavigationBar />
          <BlocksBlog />
          <Footer />
        </>
      } />
      <Route path="/blog/sweeps-blocks-splits-knowing-the-difference" element={
        <>
          <NewNavigationBar />
          <BlocksVsSweepsBlog />
          <Footer />
        </>
      } />
      <Route path="/blog/top-5-cheapest-unusual-options-flow-platforms" element={
        <>
          <NewNavigationBar />
          <CheapestOptionFlowBlog />
          <Footer />
        </>
      } />
    </Routes>
    </div>
    // </Router >
  );
};


export default App;
