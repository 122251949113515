import moment from "moment";
const PREMIUM_UNITS = ['', 'K', 'M', 'B', 'T'];

export function formatNumber(num) {
  if (num === 0) {
    return "N/A";
  }
  if (Math.abs(num) >= 1.0e+9) {
    // It's a billion or more
    return (Math.abs(num) / 1.0e+9).toFixed(2) + "B";
  } else if (Math.abs(num) >= 1.0e+6) {
    // It's a million or more
    return (Math.abs(num) / 1.0e+6).toFixed(2) + "M";
  } else if (Math.abs(num) >= 1.0e+3) {
    // It's a thousand or more
    return (Math.abs(num) / 1.0e+3).toFixed(2) + "K";
  } else {
    // It's less than a thousand
    return Math.abs(num);
  }
}

export function daysUntilCanceled(unixTimestamp) {
  const dateFromTimestamp = new Date(unixTimestamp * 1000);
  const currentDate = new Date();
  const timeDiff = dateFromTimestamp.getTime() - currentDate.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
  return daysDiff;
}

export function analyticsLogError(errorText) {

  window.gtag('event', `Error: ${errorText}`, {
    'event_category': 'ERROR',
    'event_label': errorText,
    'value': 1
  });
}

export function getMostRecentWeekday() {
  let date = new Date();

  // Check if today is Saturday (6) or Sunday (0)
  if (date.getDay() === 0) {
    // If it's Sunday, go back 2 days to Friday
    date.setDate(date.getDate() - 2);
  } else if (date.getDay() === 6) {
    // If it's Saturday, go back 1 day to Friday
    date.setDate(date.getDate() - 1);
  }

  // Format the date in YYYY-MM-DD
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function parseOptionType(ticker) {
  // Adjusted regular expression to find 'P' or 'C' followed by a series of digits at the end of the string
  const match = ticker.match(/(P|C)\d+$/);
  // If a match is found, return the 'P' or 'C'; otherwise, return an error or undefined
  return match ? match[1] : 'Option type not found';
}

export const generateCollectionNames = (startDate, endDate) => {
  const collectionNames = [];

  // Ensure startDate is before endDate
  if (startDate > endDate) {
    console.error("Start date must be before end date.");
    return collectionNames; // Return an empty array if the dates are invalid
  }

  // Create a new date instance for the start date to modify in the loop
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    // Format the current date into the desired collection name format
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // JS months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = String(currentDate.getFullYear()).slice(-2); // Get last two digits of year

    const collectionName = `_MIKE_FLOW/options/_${month}_${day}_${year}`;
    collectionNames.push(collectionName);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return collectionNames;
}

export const generateDateNames = (startDate, endDate) => {
  const collectionNames = [];

  // Ensure startDate is before endDate
  if (startDate > endDate) {
    console.error("Start date must be before end date.");
    return collectionNames; // Return an empty array if the dates are invalid
  }

  // Create a new date instance for the start date to modify in the loop
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    // Format the current date into the desired collection name format
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // JS months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = String(currentDate.getFullYear()).slice(-2); // Get last two digits of year

    const collectionName = `_${month}_${day}_${year}`;
    collectionNames.push(collectionName);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return collectionNames;
}

export const convertOptionsSymbol = (symbol) => {
  // Remove the "O:" prefix if it exists
  const cleanedSymbol = symbol.startsWith("O:") ? symbol.substring(2) : symbol;

  // Find where the date starts (assuming it's always 6 characters long)
  const dateStartIndex = cleanedSymbol.search(/\d{6}/);

  // Extract the ticker symbol
  const ticker = cleanedSymbol.substring(0, dateStartIndex);

  // Extract the expiration date and format it
  const year = cleanedSymbol.substring(dateStartIndex, dateStartIndex + 2);
  const month = cleanedSymbol.substring(dateStartIndex + 2, dateStartIndex + 4);
  const day = cleanedSymbol.substring(dateStartIndex + 4, dateStartIndex + 6);
  const expirationDate = `20${year}-${month}-${day}T00:00:00`;
  const formattedExpirationDate = new Date(expirationDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });

  // Extract the type of option (Call or Put)
  const optionType = cleanedSymbol.charAt(dateStartIndex + 6);

  // Extract the strike price and format it
  let strikePrice = parseInt(cleanedSymbol.substring(dateStartIndex + 7)) / 1000;
  strikePrice = strikePrice % 1 === 0 ? strikePrice.toString() : strikePrice.toFixed(2).replace(/\.00$|0$/, '');

  // Return the formatted string
  return `$${ticker} ${strikePrice}${optionType}, Exp: ${formattedExpirationDate}`;
}
export const optionsSymbolToJustTicker = (symbol) => {
  // Remove the "O:" prefix if it exists
  const cleanedSymbol = symbol.startsWith("O:") ? symbol.substring(2) : symbol;

  // Find where the date starts (assuming it's always 6 characters long)
  const dateStartIndex = cleanedSymbol.search(/\d{6}/);

  // Extract the ticker symbol
  const ticker = cleanedSymbol.substring(0, dateStartIndex);
  return ticker;
}
export const convertOptionsSymbolShortened = (symbol) => {
  // Remove the "O:" prefix if it exists
  const cleanedSymbol = symbol.startsWith("O:") ? symbol.substring(2) : symbol;

  // Find where the date starts (assuming it's always 6 characters long)
  const dateStartIndex = cleanedSymbol.search(/\d{6}/);

  // Extract the ticker symbol
  const ticker = cleanedSymbol.substring(0, dateStartIndex);

  // Extract the expiration date and format it
  const year = cleanedSymbol.substring(dateStartIndex, dateStartIndex + 2);
  const month = cleanedSymbol.substring(dateStartIndex + 2, dateStartIndex + 4);
  const day = cleanedSymbol.substring(dateStartIndex + 4, dateStartIndex + 6);
  const expirationDate = `20${year}-${month}-${day}T00:00:00`;
  const formattedExpirationDate = new Date(expirationDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });

  // Extract the type of option (Call or Put)
  const optionType = cleanedSymbol.charAt(dateStartIndex + 6);

  // Extract the strike price and format it
  let strikePrice = parseInt(cleanedSymbol.substring(dateStartIndex + 7)) / 1000;
  strikePrice = strikePrice % 1 === 0 ? strikePrice.toString() : strikePrice.toFixed(2).replace(/\.00$|0$/, '');

  // Return the formatted string
  return `${ticker} ${strikePrice}${optionType}, ${formattedExpirationDate}`;
}
export const convertOptionsSymbolNoTicker = (symbol) => {
  // Remove the "O:" prefix if it exists
  const cleanedSymbol = symbol.startsWith("O:") ? symbol.substring(2) : symbol;

  // Find where the date starts (assuming it's always 6 characters long)
  const dateStartIndex = cleanedSymbol.search(/\d{6}/);

  // Extract the ticker symbol
  const ticker = cleanedSymbol.substring(0, dateStartIndex);

  // Extract the expiration date and format it
  const year = cleanedSymbol.substring(dateStartIndex, dateStartIndex + 2);
  const month = cleanedSymbol.substring(dateStartIndex + 2, dateStartIndex + 4);
  const day = cleanedSymbol.substring(dateStartIndex + 4, dateStartIndex + 6);
  const expirationDate = `20${year}-${month}-${day}T00:00:00`;
  const formattedExpirationDate = new Date(expirationDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });

  // Extract the type of option (Call or Put)
  const optionType = cleanedSymbol.charAt(dateStartIndex + 6);

  // Extract the strike price and format it
  let strikePrice = parseInt(cleanedSymbol.substring(dateStartIndex + 7)) / 1000;
  strikePrice = strikePrice % 1 === 0 ? strikePrice.toString() : strikePrice.toFixed(2).replace(/\.00$|0$/, '');

  // Return the formatted string
  return `${strikePrice}${optionType}, Exp: ${formattedExpirationDate}`;
}

export function formatMoneyNumber(number) {
  if (number >= 1000000000) {
    return '$' + (number / 1000000000).toFixed(2) + 'B';
  } else if (number >= 1000000) {
    return '$' + (number / 1000000).toFixed(2) + 'M';
  } else if (number >= 1000) {
    return '$' + (number / 1000).toFixed(2) + 'K';
  } else {
    return '$' + number.toFixed(2);
  }
}
export function getGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

export function getOptionType(optionTicker) {
  // Extract the option type character from the ticker
  // console.log("We are getting the option type: " + optionTicker);

  const optionTypeChar = optionTicker.charAt(optionTicker.length - 9);
  // console.log("The ticker char: " + optionTypeChar);
  // Check if the option type character is 'C' or 'P'
  if (optionTypeChar === 'C') {
    return 'C';
  } else if (optionTypeChar === 'P') {
    return 'P';
  } else {
    return 'Unknown';
  }
}

// Helper function to convert JSON to CSV
export function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // Extract headers
  const header = Object.keys(array[0]);
  str += header.join(',') + '\r\n';

  // Extract content
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ',';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

export function triggerCSVDownload(csvContent, fileName) {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function get15MinuteCutoffESTTimestamp(today) {
  // Convert to Eastern Time
  // Note: Adjust the offset according to Daylight Saving (EST is -5, EDT is -4)
  // Get the Unix timestamp for 15 minutes ago in Eastern Time
  // const cutoffTimestamp = today.getTime() / 1000 - (15 * 60);
  return (today.getTime() / 1000) - (15 * 60);
}

const isScrolledToTop = (gridRef) => {
  if (gridRef.current) {  // Assuming you have a reference to the grid
    const scrollPosition = gridRef.current.api.getVScrollPosition();
    console.log("scrollPosition.top: ", scrollPosition.top);
    return scrollPosition.top === 0;
  }
  return true; // If gridRef is not available, assume the grid is at the top
};

function getDateString() {
  let today = new Date();

  // Get the current time in EST
  const estTime = convertToEST(today);

  // If it's before 9:30 AM EST, use the previous trading day's date
  if ((estTime.getHours() >= 0 && estTime.getHours() < 9) || (estTime.getHours() === 9 && estTime.getMinutes() < 30)) {

    // console.log("Checking previous day...");
    const previousDay = getPreviousDay(today);
    if (isTradingDay(previousDay)) {
      // console.log("testing - The previous day is a trading day, so we can use its date");
      today = previousDay;
    } else {
      // console.log("testing - The previous day is not a trading day");
    }
  }

  // If today isn't a trading day, keep going back a day until we find a trading day
  while (!isTradingDay(today)) {
    // console.log("testing - Today is not a trading day, going back to the previous day");
    today = getPreviousDay(today);
  }

  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yy = String(today.getFullYear()).substr(-2);

  return '_' + mm + '_' + dd + '_' + yy;
}


function getPreviousDay(date) {
  const estDate = convertToEST(date);
  const previousDay = new Date(estDate);
  previousDay.setDate(estDate.getDate() - 1);
  return convertToEST(previousDay);
}

function convertToEST(date) {
  const estDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  return estDate;
}

export function getDateStringStrict() {
  let today = new Date();
  // If today isn't a trading day, keep going back a day until we find a trading day
  while (!isTradingDay(today)) {
    return false;
  }

  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yy = String(today.getFullYear()).substr(-2);

  // console.log("Trading day date: " + '_' + mm + '_' + dd + '_' + yy);
  return '_' + mm + '_' + dd + '_' + yy;
}

export function optionColorShouldBeGreen(sentimentValue, cOrP) {
  if (sentimentValue === 1 || sentimentValue === 0 && cOrP === "C") {
    return true;
  }
  return false;
}
export function optionColorShouldBeGreenOnlyType(cOrP) {
  if (cOrP === "C") {
    return true;
  }
  return false;
}


export function getLast14TradingDays() {
  const tradingDaysMap = new Map();
  const today = new Date();
  let daysChecked = 0; // Start checking from today

  while (tradingDaysMap.size < 14) {
    const dateToCheck = new Date();
    dateToCheck.setDate(today.getDate() - daysChecked);

    // Checking if the day is not a weekend (Saturday = 6, Sunday = 0)
    if (dateToCheck.getDay() !== 0 && dateToCheck.getDay() !== 6 && isTradingDay(dateToCheck)) {
      const formattedDate = `options_${formatDate(dateToCheck)}`;
      const readableName = convertCollectionToReadableName(formattedDate);
      tradingDaysMap.set(formattedDate, readableName);
    }
    daysChecked++;
  }

  // console.log(`Last trading days:`, tradingDaysMap);
  return tradingDaysMap;
}


export const convertCollectionToReadableName = (collectionName) => {
  const [prefix, month, day, year] = collectionName.split('_');
  return `Options for ${month}/${day}/20${year}`;
};

export const getSentimentText = (sentimentScore) => {
  if (sentimentScore === 1) {
    return "Bullish  🐂";
  } else if (sentimentScore === 0) {
    return "Neutral  ⚖️";
  } else {
    return "Bearish 🐻";
  }
};

export function formatNumberWithCommas(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

export const convertCollectionNameToJustTheDate = (collectionName) => {
  const [prefix, month, day, year] = collectionName.split('_');
  return `${month}_${day}_${year}`;
};
export function isDeepOTM(otmPercentage) {
  return otmPercentage >= 20.0;
};
function formatDate(date) {
  const day = (`0${date.getDate()}`).slice(-2); // Ensuring two digits
  const month = (`0${date.getMonth() + 1}`).slice(-2); // Adding 1 because months are zero indexed
  const year = date.getFullYear().toString().slice(-2); // Getting last two digits of the year

  return `${month}_${day}_${year}`;
}


// console.log(getLast14TradingDays());


export function isTradingDay(date) {
  const day = date.getDay();

  // If it's Sunday or Saturday, it's not a trading day
  if (day === 0 || day === 6) {
    return false;
  }

  // List of US Holidays in YYYY-MM-DD format
  const USHolidays = [
    '2024-01-01', // New Year's Day
    '2024-01-15', // Martin Luther King Jr. Day
    '2024-02-19', // Presidents' Day
    '2024-03-29', // Good Friday (not a federal holiday but markets are closed)
    '2024-05-27', // Memorial Day
    '2024-06-19', // Juneteenth
    '2024-07-04', // Independence Day
    '2024-09-02', // Labor Day
    '2024-10-14', // Columbus Day (observed)
    '2024-11-11', // Veterans Day
    '2024-11-28', // Thanksgiving Day
    '2024-12-25', // Christmas Day
  ];

  // Manually format the date to YYYY-MM-DD to avoid time zone issues with toISOString()
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${yy}-${mm}-${dd}`;

  // If it's a holiday, it's not a trading day
  if (USHolidays.includes(formattedDate)) {
    return false;
  }

  // If it's not a weekend or a holiday, it is a trading day
  return true;
}


export function isTradingHours() {
  const currentTime = new Date();

  const estTime = new Date(currentTime.toLocaleString("en-US", { timeZone: "America/New_York" }));
  const estHour = estTime.getHours();
  const estMinute = estTime.getMinutes();
  if (estHour >= 9.5 && estHour < 16) {
    return true;
  } else if (estHour === 16 && estMinute === 0) {
    return true;
  } else {
    return false;
  }
}
export function getMarketSentiment(spyChange, vtChange, foreignChange) {
  const threshold = 0.5; // Adjust this value as needed

  // Calculate the average percent change
  const averageChange = (spyChange + vtChange + foreignChange) / 3;

  if (averageChange > threshold) {
    return 'bullish';
  } else if (averageChange < -threshold) {
    return 'bearish';
  } else {
    return 'neutral';
  }
}
const getYearMonth = (date) => date.year() * 12 + date.month();


export const combinedDisabledDate = (current, options = {}) => {
  const { from, type } = options;

  // Check if the date is outside the allowed range (between one month ago and today)
  const isOutsideAllowedRange = current && (
    current > moment().endOf('day') ||
    current < moment().subtract(2, 'months').startOf('day')
  );

  // If outside the allowed range, disable the date
  if (isOutsideAllowedRange) {
    return true;
  }

  // If 'from' is provided, apply the 7-day range restriction
  if (from) {
    const minDate = moment(from).subtract(6, 'days');
    const maxDate = moment(from).add(6, 'days');

    switch (type) {
      case 'year':
        return current.year() < minDate.year() || current.year() > maxDate.year();
      case 'month':
        return (
          getYearMonth(current) < getYearMonth(minDate) ||
          getYearMonth(current) > getYearMonth(maxDate)
        );
      default:
        return Math.abs(current.diff(from, 'days')) >= 7;
    }
  }

  // If we've reached this point, the date is not disabled
  return false;
};
export function formatMoney(n) {
  if (n >= 1e6) {
    return (n / 1e6).toFixed(2) + 'M';
  } else if (n >= 1e3) {
    return (n / 1e3).toFixed(1) + 'K';
  } else {
    return n.toString();
  }
}
export function parseTickerFromOptionsSymbol(optionsSymbol) {
  // Split the options symbol into parts
  const parts = optionsSymbol.split(':');

  if (parts.length !== 2) {
    throw new Error('Invalid options symbol format');
  }

  // Extract the ticker symbol
  const tickerSymbol = parts[1].match(/[A-Z]+/)[0];

  return tickerSymbol;
}
export const sanitizeInput = (value) => {
  return
  // return value.replace(/[<>"'&]/g, (match) => {
  //   switch (match) {
  //     case "<":
  //       return "&lt;";
  //     case ">":
  //       return "&gt;";
  //     case '"':
  //       return "&quot;";
  //     case "'":
  //       return "&#39;";
  //     case "&":
  //       return "&amp;";
  //     default:
  //       return match;
  //   }
  // });
};
// t=trade s=spot
export function mergeNetPremiumAndStockPrices(netPremiumList, stockPrices) {
  // Sort both lists by timestamp for efficient searching
  netPremiumList.sort((a, b) => a.timestamp - b.timestamp);
  stockPrices.sort((a, b) => a.t - b.t);

  return netPremiumList.map(netPremiumObj => {
    // Find the closest stock price
    let closestPrice = findClosestStockPrice(netPremiumObj.timestamp, stockPrices);

    // Return a new object with the merged data
    return {
      ...netPremiumObj,
      stockPrice: closestPrice.p,
      priceDifference: Math.abs(netPremiumObj.timestamp - closestPrice.t)
    };
  });
}

function findClosestStockPrice(timestamp, stockPrices) {
  let left = 0;
  let right = stockPrices.length - 1;

  while (left <= right) {
    let mid = Math.floor((left + right) / 2);

    if (stockPrices[mid].t === timestamp) {
      return stockPrices[mid];
    }

    if (stockPrices[mid].t < timestamp) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // After the binary search, left is the insertion point
  // We need to compare the price at left (if it exists) and left-1 to find the closest
  if (left === stockPrices.length) {
    return stockPrices[left - 1];
  }
  if (left === 0) {
    return stockPrices[0];
  }

  let leftDiff = Math.abs(stockPrices[left].t - timestamp);
  let rightDiff = Math.abs(stockPrices[left - 1].t - timestamp);

  return leftDiff < rightDiff ? stockPrices[left] : stockPrices[left - 1];
}
export function convertDateToYYYYMMDD(dateString) {
  // Split the input date by hyphen (-)
  const parts = dateString.split('-');

  // Combine the parts to get the YYYYMMDD format
  const formattedDate = parts.join('');

  return formattedDate;
}
export function getOtmPercentage(t, s) {
  if (!t || !s || !t["ot"] || !t["stp"]) {
    console.error("Invalid input");
    return null;
  }

  const optionType = t["ot"];
  const strikePrice = t["stp"];

  if (optionType !== "C" && optionType !== "P") {
    console.error("Invalid option type. Expected 'C' or 'P'.");
    return null;
  }

  const percentage = ((strikePrice - s) / s) * 100;
  const adjustedPercentage = optionType === "C" ? percentage : -percentage;

  return Number(adjustedPercentage.toFixed(2));
}

export function formatPremium(num) {
  let unitIndex = 0;

  while (num >= 1000 && unitIndex < PREMIUM_UNITS.length - 1) {
    num /= 1000;
    unitIndex++;
  }

  return `$${num.toFixed(2)}${PREMIUM_UNITS[unitIndex]}`;
}

function parseOptionSymbol(symbol) {
  // Expected format: "O:[Root][Date][OptionType][StrikePrice]"
  // Example symbol: "O:QQQ240722P00483000"

  const PREFIX_LENGTH = 2;
  const FIXED_LENGTH = 15; // Date (6) + OptionType (1) + StrikePrice (8)

  // Check if the symbol is long enough
  if (symbol.length <= PREFIX_LENGTH + FIXED_LENGTH) {
    console.error(`Invalid option symbol length: ${symbol}`);
    return null;
  }

  // Check if symbol starts with "O:"
  if (symbol.charCodeAt(0) !== 79 || symbol.charCodeAt(1) !== 58) { // 'O' = 79, ':' = 58
    console.error(`Invalid option symbol prefix: ${symbol}`);
    return null;
  }

  // Extract the root symbol without modifying the original string
  const rootEndIndex = symbol.length - FIXED_LENGTH;
  const root = symbol.substring(PREFIX_LENGTH, rootEndIndex);

  return root;
}


function parseOptionInfo(symbol) {
  if (!symbol.startsWith("O:")) {
    throw new Error(`Invalid option symbol: ${symbol}`);
  }

  const trimmed = symbol.slice(2);
  const stockSymbolLength = trimmed.length - 15;

  const stockSymbol = trimmed.slice(0, stockSymbolLength);
  const exp = trimmed.slice(stockSymbolLength, stockSymbolLength + 6);
  const expirationDate = `${exp.slice(0, 2)}-${exp.slice(2, 4)}-${exp.slice(4, 6)}`;
  const optionType = trimmed[stockSymbolLength + 6];
  const strikePrice = parseInt(trimmed.slice(stockSymbolLength + 7), 10) / 1000;

  return {
    "Stock Symbol": stockSymbol,
    "Expiration Date": expirationDate,
    "Option Type": optionType,
    "Strike Price": strikePrice,
  };
}

// Using the mapping object implementation
const sentimentMapping = {
  "A": 1,
  "AA": 2,
  "B": -1,
  "BB": -2,
  "M": 0
};

function classifySentimentScore(spreadExecution) {
  return sentimentMapping[spreadExecution] || 0;
}

function getTickerSentiment(spreadExecution, putOrCall) {
  const sentimentScore = classifySentimentScore(spreadExecution);
  const optionType = putOrCall.toLowerCase();
  if (sentimentScore === 0) {
    return 0;
  }
  if ((optionType === "c" && sentimentScore > 0) || (optionType === "p" && sentimentScore < 0)) {
    return 1;
  }
  return -1;
}


const spreadExecutionMapping = {
  "A": "Ask",
  "AA": "Above Ask",
  "M": "Mid",
  "B": "Bid",
  "BB": "Below Bid"
};

function expandSpreadExecutionSide(shortened) {
  return spreadExecutionMapping[shortened] || shortened;
}

const tradeTypeMapping = {
  "S": "SWEEP",
  "B": "BLOCK",
  "SP": "SPLIT",
  "M": "MULTI"
}

function expandTradeType(tradeType) {
  return tradeTypeMapping[tradeType] || tradeType;
}
// Define the tag mapping outside the function for better reusability
const tag_mapping = {
  "Repeat": "re",
  "Calls": "c",
  "Puts": "pu",
  "Bullish Flow": "bf",
  "Ratio": "ra",
  "Whales": "w",
  "Unusual": "u",
  "Unusual++": "u+",
  "Large Size": "ls",
  "Earnings Soon": "es",
  "Ask": "a",
  "Bid": "b",
  "ETFs": "e",
  "Stocks": "st",
  "Weeklies": "we",
  "LEAPS": "l",
  "Penny Stocks": "ps",
  "Unicorns": "un",
  "Oddities": "od",
  "Sweeps": "sw",
  "OTM": "o",
  "Deep OTM": "do",
  "High Sig": "h",
  "Vol>OI": "vo",
};

export function miniUnpackAndCompleteTrade(obj) {
  // Expand various properties using helper functions
  obj["tt"] = expandTradeType(obj.tt);
  obj["se"] = expandSpreadExecutionSide(obj.se);

  // Parse option information
  const optionInfo = parseOptionInfo(obj.sym);
  obj["ot"] = optionInfo["Option Type"];
  obj["ed"] = optionInfo["Expiration Date"];
  obj["tsy"] = optionInfo["Stock Symbol"];
  obj["stp"] = optionInfo["Strike Price"];

  // Calculate additional properties
  obj["op"] = getOtmPercentage(obj, obj.sp);
  obj["tks"] = getTickerSentiment(obj.se, obj.ot);
  obj["s"] = "N/A";
  obj["mc"] = 0;
  obj["en"] = "N/A";

  // Replace each tag in obj["tg"] using the tag_mapping
  if (Array.isArray(obj["tg"])) {
    obj["tg"] = obj["tg"]
      .map(tag => tag_mapping[tag] || tag); // Map using tag_mapping or keep original if not found
  } else {
    // Handle cases where obj["tg"] is not an array
    obj["tg"] = [];
  }

  return obj;
}

// Maps the keys of the object to the new keys and creates some new keys like otm percentage
export function unpackAndCompleteTrade(obj) {
  function expandRecursive(item) {
    if (Array.isArray(item)) {
      return item.map(expandRecursive);
    } else if (typeof item === 'object' && item !== null) {
      const expandedObj = {};
      for (const [key, value] of Object.entries(item)) {
        const expandedKey = keyMapping[key] || key;
        expandedObj[expandedKey] = expandRecursive(value);
      }
      return expandedObj;
    } else if (typeof item === 'string' && keyMapping[item]) {
      return keyMapping[item];
    } else {
      return item;
    }
  }

  const optionInfo = parseOptionInfo(obj.sym);
  obj["ot"] = optionInfo["Option Type"];
  obj["ed"] = optionInfo["Expiration Date"];
  obj["tsy"] = optionInfo["Stock Symbol"];
  obj["stp"] = optionInfo["Strike Price"];
  obj["tm"] = "N/A";

  // Create a Date object once from the unix timestamp
  const dateObj = new Date(obj["t"] * 1000);

  // Use the same Date object for both "tm" and "dot"
  obj["tm"] = dateObj.toLocaleTimeString('en-US', { hour12: false });
  obj["dot"] = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }); // MM/DD/YYYY

  obj["op"] = getOtmPercentage(obj, obj.sp);
  obj["pf"] = formatPremium(obj.p); // dont need
  obj["tks"] = getTickerSentiment(obj.se, obj.ot);
  obj["se"] = expandSpreadExecutionSide(obj.se);
  obj["tt"] = expandTradeType(obj.tt);
  obj["s"] = "N/A";
  obj["mc"] = 0;
  obj["en"] = "N/A";


  return expandRecursive(obj);
}
const keyMapping = {
  "tks": "tks",
  "ot": "optionType",
  "p": "premium",
  "s": "sector",
  "g": "greeks",
  "t": "theta",
  "d": "delta",
  "v": "vega",
  "gm": "gamma",
  "r": "rho",
  "oi": "openInterest",
  "pf": "premiumFormatted",
  "ut": "unixTimestamp",
  "tt": "tradeType",
  "mc": "marketCap",
  "cb": "currentBid",
  "mtd": "msTimeOfDay",
  "eut": "expirationUnixTimestamp",
  "cn": "companyName",
  "ca": "currentAsk",
  "cs": "currentSpread",
  "op": "otmPercentage",
  "dot": "dateOfTrade",
  "sym": "Symbol",
  "ic": "isCancelled",
  "tsy": "tickerSymbol",
  "sp": "spotPrice",
  "tg": "tags",
  "tdn": "tradeDocName",
  "stp": "strikePrice",
  "edf": "earningsDateFormatted",
  "l7bv": "last7DaysBullflowVolume",
  "lp": "livePerformance",
  "tp": "tradePrice",
  "ios": "isOutOfSequence",
  "ed": "expirationDate",
  "en": "exchangeName",
  "im": "isMultileg",
  "con": "conditions",
  "pbv": "previousBullflowVolume",
  "iv": "impliedVolatility",
  "cv": "currentVolume",
  "sc": "score",
  "se": "spreadExecution",
  "ts": "tradeSize",
  "tm": "timestamp",
  "sn": "sequenceNumber",
  "id": "id",
  "eod": "eod_performance",
  "re": "Repeat",
  "c": "Calls",
  "pu": "Puts",
  "bf": "Bullish Flow",
  "ra": "Ratio",
  "w": "Whales",
  "u": "Unusual",
  "u+": "Unusual++",
  "ls": "Large Size",
  "es": "Earnings Soon",
  "a": "Ask",
  "b": "Bid",
  "e": "ETFs",
  "st": "Stocks",
  "we": "Weeklies",
  "l": "LEAPS",
  "ps": "Penny Stocks",
  "un": "Unicorns",
  "od": "Oddities",
  "sw": "Sweeps",
  "o": "OTM",
  "do": "Deep OTM",
  "h": "High Sig",
  "vo": "Vol>OI"
};
export default getDateString;