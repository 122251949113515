import { useNavigate } from 'react-router-dom';
import { Gem, History, Home, Newspaper, UserCog, Waves } from 'lucide-react';
import Bull from './bullflow_3.png';
import { Dialog, DialogContent, styled } from '@material-ui/core';
import addEventToFirestore from './EventHelper';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { collection, query, getDocs, where } from "firebase/firestore";
import { firestore } from './firebase';
import DiscordIcon from './images/discord_icon_blue.png';
import { toast } from 'sonner';
import { Modal, ModalBody, ModalContent, ModalHeader, Tooltip } from '@nextui-org/react';


const Sidebar = ({ activeItem }) => {
  const MyDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      backgroundColor: 'black',
      borderRadius: '10px',
      minWidth: '300px',
      maxWidth: '90%',
      width: 'auto',
      height: 'auto',
      overflowY: 'auto',
      zIndex: 10000
    },
  });
  const showSignupNotif = () => {
    toast('Start your free trial to access this feature', {
      duration: 7500,
      style: {
        background: 'black',
        color: 'white',
      },
      action: {
        label: 'Let\'s Go',
        onClick: () => navigate('/signup'),
      },
      icon: <Gem size={18} />,
    });
  };
  const navigate = useNavigate();
  const auth = getAuth();
  const [firebaseUser, setUser] = useState(null);
  const [accountSettingsDialog, setAccountSettingsDialog] = useState(false);
  const [openAccountSettingsDialog, setOpenAccountSettingsDialog] = useState(false);
  const closeAccountSettings = () => {
    if (firebaseUser != null) {
      setAccountSettingsDialog(false);
      return;
    }
    showSignupNotif();
  };
  const openAccountSettings = () => {
    if (firebaseUser != null) {
      setAccountSettingsDialog(true);
      return;
    }
    showSignupNotif();
  };

  const handleBlogClick = () => {
    window.open('https://blog.bullflow.io', '_blank');
  };

  const toggleAccountDialog = () => {
    setOpenAccountSettingsDialog(true);
  };
  const closeAccountDialog = () => {
    setOpenAccountSettingsDialog(false);
  };
  const handleDiscordClick = () => {
    window.open('https://discord.gg/mZ6JwbsRH7', '_blank');
  };
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(`User is signed in: ${user.email}`);
        setUser(user);
        fetchSubscriptionType(user);
      } else {
        if (activeItem !== 'flow') {
          navigate('/pricing');
        }
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);
  const fetchSubscriptionType = async (user_) => {
    try {
      if (user_ != null) {
        const paymentsCollection = collection(firestore, 'payments');
        const q = query(paymentsCollection, where('user', '==', user_.email));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.log(`Error 588, user does not have a subscription. User: ${user_.email}`);
          navigate('/pricing')
        } else {
          querySnapshot.forEach((doc) => {
            let subtype = doc.data().subscriptionType;
            let canceling = doc.data().canceling;
            let cancelAt_ = doc.data().cancelAt;
            let subscriptionStatus = doc.data().subscriptionStatus;

            if (cancelAt_ != null && canceling != null) {
              if (canceling === true) {
                // setCancelAt(daysUntilCanceled(cancelAt_));
              }
            }
            if (subscriptionStatus != null && (subscriptionStatus === "deleted" || subscriptionStatus === "canceled" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "incomplete" || subscriptionStatus === "past_due" || subscriptionStatus === "unpaid")) {
              navigate('/pricing');
            }

            if (subscriptionStatus != null && (subscriptionStatus === "deleted" || subscriptionStatus === "canceled" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "incomplete" || subscriptionStatus === "past_due" || subscriptionStatus === "unpaid")) {                            // Open stripe portal
              navigate('/pricing');
            }
            if (subtype != null && (subtype === "yearly" || subtype === "monthly")) {
              // Nothing
            } else {
              navigate('/pricing');
            }
          });
        }
      } else {
        console.error("error code 19277");
      }
    } catch (error) {
      console.error('Error fetching subscription type');
    }
  };
  return (
    <div
      style={{
        width: '50px',
        height: '100vh',
        background: '#15181d',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img
          src={Bull}
          alt="Bullflow Logo"
          style={{ marginBottom: '40px', marginTop: '7.5px', cursor: 'pointer', height: '35px' }}
          onClick={() =>
            navigate('/')
          }

        />
        <Tooltip delay={750} content="Home">
          <Home
            onClick={() => {
              if (firebaseUser != null) {
                navigate('/home');
              } else {
                showSignupNotif();
              }
            }}
            size={18}
            color={activeItem === 'home' ? '#b3b3b3' : '#6B7280'}
            style={{ marginBottom: '30px', cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip delay={750} content="Real-Time Options Flow Dashboard">
          <Waves
            onClick={() => {
              if (firebaseUser != null) {
                navigate('/flow');
              } else {
                showSignupNotif();
              }
            }}
            size={18}
            color={activeItem === 'flow' ? '#b3b3b3' : '#6B7280'}
            style={{ marginBottom: '30px', cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip delay={750} content="Historical Flow">
          <History
            onClick={() => {
              if (firebaseUser != null) {
                navigate('/historical-flow');
              } else {
                showSignupNotif();
              }
            }}
            size={18}
            color={activeItem === 'historical-flow' ? '#b3b3b3' : '#6B7280'}
            style={{ marginBottom: '30px', cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip delay={750} content="Blog & Education Resources">
          <Newspaper
            onClick={() => {
              if (firebaseUser != null) {
                handleBlogClick();
              } else {
                showSignupNotif();
              }
            }}
            size={18}
            color={activeItem === 'news' ? '#b3b3b3' : '#6B7280'}
            style={{ marginBottom: '30px', cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip delay={500} content="Official Bullflow Discord">
          <img
            src={DiscordIcon}
            alt="Discord Icon"
            style={{ marginBottom: '30px', cursor: 'pointer', height: '20px', width: '20px' }}
            onClick={() => {
              handleDiscordClick();
            }
            }
          />
        </Tooltip>
        {/* Additional icons and content can be added here */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
        {/* Profile icon at the bottom */}
        {
          firebaseUser && <UserCog size={18} color="#6B7280" style={{ cursor: 'pointer' }} onClick={openAccountSettings} />
        }
      </div>
      <Modal
        backdrop="opaque"
        isOpen={accountSettingsDialog}
        onOpenChange={setOpenAccountSettingsDialog}
        onClose={closeAccountSettings}
        radius="lg"
        classNames={{
          body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          base: "border-[#292f46] bg-[#111111] text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          <ModalHeader>Manage</ModalHeader>
          <ModalBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <h4 style={{ color: 'white', margin: '0' }}>Welcome</h4>
            {firebaseUser && (
              <p style={{ color: 'grey', margin: '0 0 0 0', fontSize: '.75em' }}>
                {firebaseUser.email}
              </p>
            )}

            <p
              style={{ color: 'white', margin: '15px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
              onClick={() => {
                window.gtag('event', 'Suggest a request button', {
                  'event_category': 'Button',
                  'event_label': 'Suggest a request',
                  'value': 1,
                });
                addEventToFirestore("Click Suggest request", firebaseUser?.email || "");
                window.open('https://bullflow.io/contact', '_blank');
              }}
            >
              Send Feedback
            </p>

            <p
              style={{ color: 'white', margin: '5px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
              onClick={() => window.open('https://bullflow.io/contact', '_blank')}
            >
              Contact
            </p>
            <p
              style={{ color: 'white', margin: '5px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
              onClick={() => {
                window.gtag('event', 'Payment portal button', {
                  'event_category': 'Button',
                  'event_label': 'Payment portal',
                  'value': 1,
                });
                addEventToFirestore("Click Payment Portal", firebaseUser?.email || "");
                window.open('https://billing.stripe.com/p/login/00g4jA2096o86FW288', '_blank');
              }}
            >
              Payment Portal
            </p>
            <p
              style={{ color: 'white', margin: '15px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
              onClick={() => {
                window.gtag('event', 'Sign out button', {
                  'event_category': 'Button',
                  'event_label': 'Sign out',
                  'value': 1,
                });
                addEventToFirestore("Click Sign out", firebaseUser?.email || "");
                auth.signOut()
                  .then(() => navigate('/'))
                  .catch((error) => {
                    console.error("User error 22200");
                    navigate('/');
                  });
              }}
            >
              Sign Out
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>

    </div>
  );
};

export default Sidebar;
